import { MainNavLink } from "./main-nav-link";
import AppLink from "../../app-link";
import { useLocation } from "react-router-dom";
import { useGetMainNavDefinition } from "../main-nav-definition";
import { MainNavProfile } from "./main-nav-profile";
import { Box, Image, Stack, MenuItem } from "@chakra-ui/react";
import { ContentContainer, CONTENT_CONTAINER_WIDTH } from "../../content-container";
import Logo from "../../../assets/creatives-scale-logo-color-white-text.png";
import { MainNavDropdownMenu } from "./main-nav-dropdown-menu";

export const MAIN_NAV_HEIGHT = "64px";

export function MainNavFull() {
  const location = useLocation();

  const navDefinition = useGetMainNavDefinition();

  return (
    <Box
      height="64px"
      backgroundColor="#333333"
      display="grid"
      gridTemplateColumns={`minmax(0px, 1fr) minmax(300px, ${CONTENT_CONTAINER_WIDTH}) minmax(0px, 1fr)`}
      justifyContent="stretch"
      alignItems="center"
      position="sticky"
      top="0"
      zIndex={999}
      marginBottom={2}
      boxShadow="2px 0px 12px rgba(0, 0, 0, 0.6)"
    >
      <Box gridColumn="1">
        <Box paddingLeft={4}>
          <AppLink to="/">
            <Image objectFit="contain" src={Logo} maxHeight={50} maxWidth={200} />
          </AppLink>
        </Box>
      </Box>
      <ContentContainer gridColumn="2">
        <Stack isInline spacing={8} display="flex" alignItems="center">
          {navDefinition.map((navItem) => {
            if (!navItem.visible) {
              return null;
            }
            if (navItem.submenu) {
              return (
                <MainNavDropdownMenu
                  key={navItem.name}
                  title={navItem.name}
                  isActive={location.pathname.startsWith(navItem.to)}
                >
                  {navItem.submenu.map((navSubItem) => {
                    if (!navSubItem.visible) {
                      return null;
                    }
                    return (
                      <MenuItem
                        key={navSubItem.name}
                        as={AppLink}
                        {...{ to: navSubItem.to, isExternal: navSubItem.isExternal }}
                      >
                        {navSubItem.name}
                      </MenuItem>
                    );
                  })}
                </MainNavDropdownMenu>
              );
            } else {
              return (
                <MainNavLink
                  key={navItem.name}
                  to={navItem.to}
                  isExternal={navItem.isExternal}
                  isActive={location.pathname.startsWith(navItem.to)}
                >
                  {navItem.name}
                </MainNavLink>
              );
            }
          })}
        </Stack>
      </ContentContainer>
      <Box color="white" gridColumn="3">
        <MainNavProfile />
      </Box>
    </Box>
  );
}
