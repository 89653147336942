import { ReactNode } from "react";
import { useTheme } from "@chakra-ui/react";

interface Props {
  children: ReactNode;
  colSpan?: number;
}

export function TableCell(props: Props) {
  const { children, colSpan } = props;
  const theme = useTheme();

  return (
    <td
      colSpan={colSpan}
      style={{
        display: "table-cell",
        padding: theme.space[4],
        fontSize: "14px",
        textAlign: "left",
        borderBottom: `1px solid #E3E8EF`,
      }}
    >
      {children}
    </td>
  );
}
