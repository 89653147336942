import { useEffect, useContext, ReactNode, Children } from "react";
import { TableContext } from "./table-context";

interface Props {
  children: ReactNode;
}

export function TableHead(props: Props) {
  const { children } = props;
  const tableContext = useContext(TableContext);

  useEffect(() => {
    const child = Children.only(children) as any;
    const columnCount = child?.props?.children?.length ?? 0;
    tableContext.setColumnCount(columnCount);
  }, [children, tableContext]);

  return <thead style={{ backgroundColor: "#FAFAFA" }}>{children}</thead>;
}
