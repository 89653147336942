import { useState } from "react";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

interface Props {
  onChange: (value: string) => void;
  placeholder: string;
  defaultValue?: string;
}

function SearchBox(props: Props) {
  const { onChange, placeholder, defaultValue } = props;

  const [timeoutHandler, setTimeoutHandler] = useState<NodeJS.Timeout | null>(null);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    if (timeoutHandler) {
      clearTimeout(timeoutHandler);
    }
    const timeout = setTimeout(() => {
      onChange(value);
    }, 250);
    setTimeoutHandler(timeout);
  }

  return (
    <InputGroup size="md">
      <InputLeftElement width="3rem">
        <SearchIcon />
      </InputLeftElement>
      <Input pl="3rem" placeholder={placeholder} onChange={handleChange} defaultValue={defaultValue} />
    </InputGroup>
  );
}

export default SearchBox;
