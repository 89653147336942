import { createContext } from "react";
import { PanelStatus } from "./status";

export interface PanelContextValue {
  isCollapsible: boolean;
  isCollapsed: boolean;
  toggleCollapse: () => void;
  status?: PanelStatus;
}

export const PanelContext = createContext<PanelContextValue>({
  isCollapsible: false,
  isCollapsed: false,
  toggleCollapse: () => {
    // no-op
  },
});
