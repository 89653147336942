import { useContext } from "react";
import { AuthContext } from "../../../core/auth-manager";
import AppIcon from "../../app-icon";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { Box, Button, Menu, MenuButton, MenuList, MenuItem, Stack, Text, Badge, Divider } from "@chakra-ui/react";
import AppLink from "../../app-link";
import { currencyFormatter } from "../../../util/formatter";

export function MainNavProfile() {
  const authContext = useContext(AuthContext);
  const { viewer, logout } = authContext;

  return (
    <Box paddingRight={2} textAlign="right" fontSize="14px">
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Stack isInline spacing={2} shouldWrapChildren>
          <Box>
            <Text fontSize="sm">{viewer?.user?.fullName ?? "Not Logged In"}</Text>
            <Badge colorScheme="green">
              +{currencyFormatter.format(viewer?.creativesScaleUser?.unpaidBalance ?? 0)}
            </Badge>
          </Box>
        </Stack>
        <Menu autoSelect={false}>
          <Button
            variant="ghost"
            as={MenuButton}
            color="white"
            backgroundColor="#333333"
            _hover={{ backgroundColor: "#333333" }}
            _active={{ backgroundColor: "#333333" }}
          >
            <AppIcon icon={faEllipsisV} />
          </Button>
          <MenuList color="black">
            <MenuItem as={AppLink} {...{ to: "/profile" }} color="black">
              Profile
            </MenuItem>
            <Divider />
            <MenuItem as={AppLink} {...{ to: "/welcome" }} color="black">
              Modify Work Interests
            </MenuItem>
            <Divider />
            <MenuItem
              color="black"
              onClick={() => {
                logout();
              }}
            >
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Box>
  );
}
