import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

export function TableHeaderCell(props: Props) {
  const { children } = props;

  return (
    <th
      style={{
        display: "table-cell",
        padding: "16px",
        fontSize: "12px",
        textAlign: "left",
        borderBottom: `1px solid #E3E8EF`,
        borderRadius: "4px 4px 0 0",
      }}
    >
      {children}
    </th>
  );
}
