import { BoxProps, Box, Flex } from "@chakra-ui/react";

/*
  BREAKPOINTS
  // base
  // sm 480px upwards
  // md 768px upwards
  // lg 992px upwards
*/

interface Props extends BoxProps {
  centerHorizontally?: boolean;
  disableBottomGutter?: boolean;
}

export const CONTENT_CONTAINER_WIDTH = "1120px";

export function ContentContainer(props: Props) {
  const { centerHorizontally, disableBottomGutter, ...rest } = props;

  if (centerHorizontally) {
    return (
      <Flex justifyContent="center">
        <Box
          width={{ base: "95%", xl: CONTENT_CONTAINER_WIDTH }}
          marginBottom={disableBottomGutter ? 0 : 12}
          {...rest}
        />
      </Flex>
    );
  }
  return <Box width={{ base: "95%", xl: CONTENT_CONTAINER_WIDTH }} {...rest} />;
}
