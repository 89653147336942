import { useContext } from "react";
import { TableContext } from "./table-context";
import { TableCell } from "./table-cell";
import { Button, Text, Box } from "@chakra-ui/react";
import AppIcon from "../app-icon";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { TableRow } from "./table-row";

interface Props {
  totalCount: number;
  offset: number;
  limit: number;
  onPreviousPage: () => void;
  onNextPage: () => void;
}

export function TablePagination(props: Props) {
  const { totalCount, offset, limit, onPreviousPage, onNextPage } = props;
  const tableContext = useContext(TableContext);

  if (totalCount === 0) {
    return null;
  }

  const isPaginationRequired = totalCount > limit;

  if (!isPaginationRequired) {
    return (
      <TableRow key="pagination">
        <TableCell colSpan={tableContext.columnCount}>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Text>
              <strong>{totalCount}</strong> total item
              {totalCount > 1 ? "s" : ""}
            </Text>
          </Box>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow key="pagination">
      <TableCell colSpan={tableContext.columnCount}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <Button
            variant="ghost"
            aria-label="Previous page"
            marginRight={1}
            isDisabled={offset === 0}
            onClick={() => onPreviousPage()}
          >
            <AppIcon icon={faChevronLeft} />
          </Button>
          <Button
            variant="ghost"
            aria-label="Next page"
            marginRight={2}
            isDisabled={totalCount < offset + limit + 1}
            onClick={() => onNextPage()}
          >
            <AppIcon icon={faChevronRight} />
          </Button>
          <Text>
            Showing {offset + 1} - {Math.min(offset + limit, totalCount)} of {totalCount} items
          </Text>
        </Box>
      </TableCell>
    </TableRow>
  );
}
