import { Alert, AlertIcon, Box, Text, Link } from "@chakra-ui/react";
import { AppColors } from "../../core/custom-theme";

export function ContinueOnboarding() {
  return (
    <Alert status="warning" marginTop={2} variant="left-accent">
      <AlertIcon />
      <Box>
        <Text>
          In order to get paid, you need to{" "}
          <Link color={AppColors.blue} href="/start-stripe-onboarding">
            finish the Stripe Onboarding
          </Link>
        </Text>
      </Box>
    </Alert>
  );
}
