import { createContext } from "react";

export interface TableContextValue {
  columnCount: number;
  setColumnCount: (columns: number) => void;
  isLoading: boolean;
  isEmpty: boolean;
}

export const TableContext = createContext<TableContextValue>({
  columnCount: 0,
  setColumnCount: () => {
    // Do nothing
  },
  isLoading: true,
  isEmpty: true,
});
