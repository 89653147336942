import { useState, useContext } from "react";
import AppLink from "../../app-link";
import AppIcon from "../../app-icon";
import { faBars, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { useGetMainNavDefinition } from "../main-nav-definition";
import { useLocation } from "react-router-dom";
import { MainNavSmallLink } from "./main-nav-small-link";
import Logo from "../../../assets/creatives-scale-icon-color-white-text.png";
import {
  Box,
  Drawer,
  Button,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Image,
  Stack,
  Flex,
  Text,
  Badge,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Divider,
} from "@chakra-ui/react";
import { AuthContext } from "../../../core/auth-manager";
import { MainNavSmallDropdownMenu } from "./main-nav-small-dropdown-menu";
import { currencyFormatter } from "../../../util/formatter";

export function MainNavSmall() {
  const [isMainNavOpen, setIsMainNavOpen] = useState(false);
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const { viewer, logout } = authContext;

  const navDefinitions = useGetMainNavDefinition();

  return (
    <Box
      height="64px"
      backgroundColor="#333333"
      position="sticky"
      top="0"
      zIndex={999}
      display="grid"
      alignItems="center"
      gridTemplateColumns="1fr 100px 1fr"
      marginBottom={2}
      boxShadow="2px 0px 12px rgba(0, 0, 0, 0.6)"
    >
      <Drawer
        isOpen={isMainNavOpen}
        placement="left"
        isFullHeight
        onClose={() => setIsMainNavOpen(false)}
        returnFocusOnClose={false}
        closeOnOverlayClick={false}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <Box padding={2} minWidth={250} marginTop={8}>
              {navDefinitions.map((navItem) => {
                if (!navItem.visible) {
                  return null;
                }
                if (!navItem.submenu) {
                  return (
                    <Box key={navItem.name} marginBottom={2} fontSize="1rem">
                      <MainNavSmallLink
                        to={navItem.to}
                        isExternal={navItem.isExternal}
                        isActive={location.pathname.startsWith(navItem.to)}
                      >
                        {navItem.name}
                      </MainNavSmallLink>
                    </Box>
                  );
                }
                return (
                  <MainNavSmallDropdownMenu
                    key={navItem.name}
                    title={navItem.name}
                    isActive={location.pathname.startsWith(navItem.to)}
                  >
                    {navItem.submenu.map((subItem) => {
                      if (!subItem.visible) {
                        return null;
                      }
                      return (
                        <Box key={subItem.name} marginBottom={2} marginLeft={2} fontSize="1rem">
                          <MainNavSmallLink
                            to={subItem.to}
                            isExternal={subItem.isExternal}
                            isActive={location.pathname.startsWith(subItem.to)}
                          >
                            {subItem.name}
                          </MainNavSmallLink>
                        </Box>
                      );
                    })}
                  </MainNavSmallDropdownMenu>
                );
              })}
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Box gridColumn={1} paddingLeft={1}>
        <Button
          variant="ghost"
          backgroundColor="#333333"
          _hover={{ backgroundColor: "#666666" }}
          _active={{ backgroundColor: "#777777" }}
          onClick={() => setIsMainNavOpen(true)}
        >
          <AppIcon icon={faBars} color="white" />
        </Button>
      </Box>
      <Flex gridColumn={2} justifyContent="center">
        <AppLink to="/">
          <Image objectFit="contain" src={Logo} maxHeight={50} />
        </AppLink>
      </Flex>
      <Box gridColumn={3} textAlign="right" paddingRight={1} color="white">
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Stack isInline spacing={2} shouldWrapChildren>
            <Box>
              <Text fontSize="sm">{viewer?.user?.fullName ?? "Not Logged In"}</Text>
              <Badge colorScheme="green">
                +{currencyFormatter.format(viewer?.creativesScaleUser?.unpaidBalance ?? 0)}
              </Badge>
            </Box>
          </Stack>
          <Menu autoSelect={false}>
            <Button
              variant="ghost"
              as={MenuButton}
              color="white"
              backgroundColor="#333333"
              _hover={{ backgroundColor: "#333333" }}
              _active={{ backgroundColor: "#333333" }}
            >
              <AppIcon icon={faEllipsisV} />
            </Button>
            <MenuList color="black">
              <MenuItem as={AppLink} {...{ to: "/profile" }} color="black">
                Profile
              </MenuItem>
              <Divider />
              <MenuItem
                color="black"
                onClick={() => {
                  logout();
                }}
              >
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
}
