import { useContext } from "react";
import { AuthContext } from "../../core/auth-manager";

interface NavItemDefinition {
  name: string;
  to: string;
  visible: boolean;
  isExternal?: boolean;
}

interface NavItemTopLevelDefinition extends NavItemDefinition {
  submenu?: NavItemDefinition[];
}

export function useGetMainNavDefinition() {
  const { viewer } = useContext(AuthContext);

  const navItems: NavItemTopLevelDefinition[] = [
    {
      name: "My Tasks",
      to: "/my-tasks",
      visible: true,
    },
    {
      name: "Find Tasks",
      to: "/find-tasks",
      visible: true,
    },
    {
      name: "Reports",
      to: "/user-task-report",
      visible: true,
    },
    {
      name: "Make More Money",
      to: "https://www.creativesscale.com/tier-support/",
      visible: true,
      isExternal: true,
    },
    {
      name: "Support",
      to: "https://www.creativesscale.com/support/",
      visible: true,
      isExternal: true,
    },
    {
      name: "FAQ",
      to: "https://www.creativesscale.com/faqs-content/",
      visible: true,
      isExternal: true,
    },
    {
      name: "Admin",
      to: "/admin",
      visible: viewer?.creativesScaleUser?.isAdmin ?? false,
      submenu: [
        {
          name: "Writing Sample Reviews",
          to: "/admin/writing-samples",
          visible: true,
        },
        {
          name: "Team Members",
          to: "/admin/team-members",
          visible: true,
        },
        {
          name: "Grammar Test Questions",
          to: "/admin/grammar-test-questions",
          visible: true,
        },
        {
          name: "Writing Sample Topics",
          to: "/admin/writing-sample-topics",
          visible: true,
        },
        {
          name: "Tasks",
          to: "/admin/tasks",
          visible: true,
        },
        {
          name: "QA View",
          to: "/admin/qa-view",
          visible: true,
        },
        {
          name: "Task Reports",
          to: "/admin/user-task-reports",
          visible: true,
        },
      ],
    },
  ];

  return navItems;
}
