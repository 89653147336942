export const TASK_TYPES = {
  CHECKLIST: "checklist",
  CONTENT: "content",
  CONTENT_REVIEW: "content-review",
  BLOG_CONTENT: "blog-content",
  BLOG_CONTENT_REVIEW: "blog-content-review",
  KEYWORD_RESEARCH: "keyword-research-v2",
  KEYWORD_RESEARCH_CUSTOMER_REVIEW: "keyword-research-customer-review",
  CONTENT_CALENDAR: "content-calendar",
  CONTENT_STRATEGY: "content-strategy",
  CONTENT_IDEATION: "content-ideation",
  TITLE_TAG_META_DESCRIPTION: "title-tag-meta-description",
  REVIEW_TITLE_TAG_META_DESCRIPTION: "review-title-tag-meta-description",
  GBP_UPDATE: "gbp-update",
  PUBLISH_GBP_UPDATE: "publish-gbp-update",
};
