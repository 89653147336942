import { ReactNode } from "react";
import { LinkProps, Menu, MenuButton, MenuList, Box, Link } from "@chakra-ui/react";
import AppIcon from "../../app-icon";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { AppColors } from "../../../core/custom-theme";

interface Props extends LinkProps {
  title: string;
  children: ReactNode;
  isActive: boolean;
}

/* AppLink is a wrapper adding custom styling to React Routers Link component */
export function MainNavDropdownMenu(props: Props) {
  const { isActive, title, children } = props;

  return (
    <Box marginRight={3}>
      <Menu>
        <MenuButton
          as={Link}
          color={isActive ? "white" : AppColors.textDarkGray}
          textDecoration="none"
          fontSize="16px"
          _hover={{
            textDecoration: "none",
            color: isActive ? "white" : "#d5d5d5",
          }}
          _focus={{
            border: 0,
          }}
        >
          {title}
          <AppIcon icon={faAngleDown} standardLeftMargin />
        </MenuButton>
        <MenuList>{children}</MenuList>
      </Menu>
    </Box>
  );
}
