import { OnboardingNeeded } from "./onboarding-needed";
import { ContinueOnboarding } from "./continue-onboarding";
import { useStripeActionBarQuery } from "../../generated/graphql";
import { STRIPE_ACCOUNT_STATUS } from "../../constants/stripe-account-status";

export function StripeActionBar() {
  const queryResult = useStripeActionBarQuery({
    fetchPolicy: "cache-and-network",
  });

  const statusId = queryResult.data?.viewer.creativesScaleUser?.stripeAccountStatus.id ?? null;
  const isEligibleForPayments = queryResult.data?.viewer.creativesScaleUser?.isEligibleForPayments ?? false;
  if (queryResult.loading && !statusId) {
    return null;
  }

  if (!statusId) {
    return null;
  }
  if (!isEligibleForPayments) {
    return null;
  }
  if (statusId === STRIPE_ACCOUNT_STATUS.NOT_STARTED.id) {
    return <OnboardingNeeded />;
  } else if (statusId === STRIPE_ACCOUNT_STATUS.IN_PROGRESS.id) {
    return <ContinueOnboarding />;
  }
  return null;
}
