import Color from "color";
import { DateTime } from "luxon";
import {
  Grid,
  Stack,
  Flex,
  Box,
  Text,
  Input,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Button,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { Navigate } from "react-router-dom";
import { TaskWorkspaceCommonProps } from "../../task-workspace-common-props";
import { useContent } from "./use-content";
import { Loader } from "../../../../components/loader";
import { PageHeading } from "../../../../components/page-heading";
import { InformationPanel } from "./information-panel";
import { PanelGroup, PanelTop, PanelMiddle, PanelBottom } from "../../../../components/panels";
import { PrimaryButton } from "../../../../components/buttons";
import { DangerTextButton } from "../../../../components/buttons/danger-text-button";
import { ValidationChecklistPanel } from "../../../../components/validation-checklist-panel";
import { AppColors } from "../../../../core/custom-theme";
import { RevisionPanel } from "../../../../common/revisions-panel";
import { ContentEditor } from "../../../../components/content-editor";
import { LOADER_TYPE, WEB_COPY_PRODUCTS, HOUR_MINUTE_FORMAT, DATE_MINUTE_AT_FORMAT } from "../../../../constants";
import { ChecklistPanel } from "../../../../common/checklist-panel";

export function Content(props: TaskWorkspaceCommonProps) {
  const { onAbandon } = props;
  const {
    loading,
    formik,
    task,
    validationCheckSummary,
    lastAutosavedAt,
    onContentItemUpdate,
    needsAutosave,
    selectedChecklistItems,
    unselectedChecklistItems,
    onChecklistItemSelected,
  } = useContent(props);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  if (loading) {
    return <Loader type={LOADER_TYPE.fullView} />;
  }

  if (!task) {
    toast({ title: "Invalid Task", description: "The specified task was invalid. Please try again." });
    return <Navigate to="/" />;
  }

  const isWebCopy = WEB_COPY_PRODUCTS.includes(task.order.partnerProduct.product.id);

  function renderCountDown(expiresAt: Date) {
    return (
      <Alert status="warning" marginY={2} variant="left-accent">
        <AlertIcon />
        <Box display="flex">
          <Text>
            This task will expire on <strong>{DateTime.fromJSDate(expiresAt).toFormat(DATE_MINUTE_AT_FORMAT)}</strong>.
            Please submit for approval before then to avoid a penalty to your account.
          </Text>
        </Box>
      </Alert>
    );
  }

  if (!!task?.expiresAt && task.expiresAt < new Date()) {
    toast({
      title: "Task Expired",
      description: "The specified task is expired. This task will be in available queue.",
    });
    return <Navigate to="/" />;
  }

  return (
    <>
      <PageHeading>Content</PageHeading>
      <Alert status="error" marginBottom={2}>
        <AlertIcon />
        {`We understand that AI can help aid in writing content, however anyone caught using AI for the actual content will be immediately terminated from CreativesScale.`}
      </Alert>
      {!!task?.expiresAt && renderCountDown(task.expiresAt)}
      <Grid gridTemplateColumns="1fr 1.5fr" gridGap={4}>
        <Stack spacing={4}>
          <RevisionPanel reviewTasks={task.reviewTasks.nodes} />
          {!isWebCopy && (
            <ChecklistPanel
              productId={task.order.partnerProduct.product.id}
              selectedItems={selectedChecklistItems}
              onChange={onChecklistItemSelected}
            />
          )}
          <InformationPanel task={task} />
        </Stack>
        <Stack spacing={4}>
          <PanelGroup>
            <PanelTop>
              <Input
                size="lg"
                id="title"
                value={formik.values.title}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  formik.handleChange(e);
                  onContentItemUpdate(e.target.value, formik.values.content);
                }}
              />
            </PanelTop>
            <PanelMiddle>
              <ContentEditor
                onChange={(newValue) => {
                  formik.setFieldValue("content", newValue);
                  if (newValue !== formik.values.content) {
                    onContentItemUpdate(formik.values.title, newValue);
                  }
                }}
                height={500}
                min_height={500}
                initialValue={task.contentItem?.content ?? ""}
              />
            </PanelMiddle>
            <PanelBottom showTopBorder>
              <Flex justifyContent="space-between" alignItems="center">
                <Box marginLeft={2}>
                  {!needsAutosave && !!lastAutosavedAt && (
                    <Text fontSize="sm" color={AppColors.textGray}>
                      Last Saved {DateTime.fromJSDate(lastAutosavedAt).toFormat(HOUR_MINUTE_FORMAT)}
                    </Text>
                  )}
                </Box>
                <Stack isInline spacing={2} justifyContent="flex-end">
                  <DangerTextButton onClick={onOpen}>Unassign Task</DangerTextButton>
                  <PrimaryButton
                    isLoading={formik.isSubmitting}
                    onClick={formik.submitForm}
                    isDisabled={unselectedChecklistItems.length !== 0}
                  >
                    Submit
                  </PrimaryButton>
                </Stack>
              </Flex>
            </PanelBottom>
          </PanelGroup>
          {validationCheckSummary && <>{!isWebCopy && <ValidationChecklistPanel {...validationCheckSummary} />}</>}
        </Stack>
      </Grid>
      <Modal onClose={onClose} size="xl" isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Unassign Task?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Unassigning a task comes with a penalty. Excessive penalties could result in restricted access or banning
            your account.
          </ModalBody>
          <ModalFooter>
            <Button fontSize="14px" paddingX="30px" paddingY="10px" marginRight="20px" onClick={onClose}>
              Cancel
            </Button>
            <Button
              color={"white"}
              backgroundColor={AppColors.error}
              _hover={{
                bg: Color(AppColors.error).darken(0.2).hex(),
              }}
              _active={{
                bg: Color(AppColors.error).darken(0.4).hex(),
              }}
              fontSize="14px"
              paddingX="30px"
              paddingY="10px"
              onClick={onAbandon}
            >
              Unassign
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
