import { useState, ReactNode } from "react";
import AppIcon from "../../app-icon";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { Box, Collapse, LinkProps, Button } from "@chakra-ui/react";
import { AppColors } from "../../../core/custom-theme";

interface Props extends LinkProps {
  title: string;
  children: ReactNode;
  isActive: boolean;
}

/* AppLink is a wrapper adding custom styling to React Routers Link component */
export function MainNavSmallDropdownMenu(props: Props) {
  const { title, children, isActive } = props;
  const [isOpen, setIsOpen] = useState(props.isActive);

  function toggleOpen(event: React.MouseEvent<any, MouseEvent>) {
    event.preventDefault();
    setIsOpen((current) => !current);
  }

  return (
    <Box marginBottom={2}>
      <Box marginBottom={isOpen ? 2 : 0}>
        <Button
          variant="link"
          onClick={toggleOpen}
          fontSize="16px"
          fontWeight="normal"
          color={isActive ? AppColors.blue : AppColors.textDarkGray}
          _hover={{
            color: isActive ? AppColors.blue : AppColors.textGray,
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          {title}
          <AppIcon icon={isOpen ? faAngleUp : faAngleDown} standardLeftMargin />
        </Button>
      </Box>
      <Collapse in={isOpen}>{children}</Collapse>
    </Box>
  );
}
