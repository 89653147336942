import { useContext, useState, useEffect, ReactNode } from "react";
import { TableContext } from "./table-context";
import { Box, Text, Stack } from "@chakra-ui/react";
import { TableCell } from "./table-cell";
import ContentLoader from "react-content-loader";
import { TableRow } from "./table-row";
import AppIcon from "../app-icon";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { AppColors } from "../../core/custom-theme";

interface Props {
  children: ReactNode;
}

export function TableBody(props: Props) {
  const { children } = props;
  const tableContext = useContext(TableContext);

  const [allowLoader, setAllowLoader] = useState(false);
  useEffect(() => {
    const allowLoaderTimeout = setTimeout(() => {
      if (tableContext.isLoading) {
        setAllowLoader(true);
      } else {
        setAllowLoader(false);
      }
    }, 250);
    return () => {
      clearTimeout(allowLoaderTimeout);
    };
  }, [allowLoader, tableContext.isLoading]);
  if (tableContext.isLoading && allowLoader) {
    const fakeRows = Array.from({ length: 5 }, (x, i) => i);
    const fakeColumns = Array.from({ length: tableContext.columnCount }, (x, i) => i);
    return (
      <tbody>
        {fakeRows.map((row) => (
          <tr key={row}>
            {fakeColumns.map((x) => (
              <TableCell key={x}>
                <Box maxWidth={200}>
                  <ContentLoader height={20} width={500} speed={2} preserveAspectRatio="xMinYMid slice">
                    <rect x="0" y="0" rx="5" ry="5" width={500} height="20" />
                  </ContentLoader>
                </Box>
              </TableCell>
            ))}
          </tr>
        ))}
      </tbody>
    );
  }

  if (tableContext.isEmpty) {
    return (
      <tbody>
        <TableRow key="empty">
          <TableCell colSpan={tableContext.columnCount}>
            <Stack spacing={2} textAlign="center" color={AppColors.textGray}>
              <Text>
                <AppIcon icon={faInbox} size="4x" />
              </Text>
              <Text marginTop={2}>No data</Text>
            </Stack>
          </TableCell>
        </TableRow>
      </tbody>
    );
  }

  return <tbody>{children}</tbody>;
}
