import { LinkProps } from "@chakra-ui/react";
import AppLink, { AppLinkProps } from "../../app-link";
import { AppColors } from "../../../core/custom-theme";

interface Props extends AppLinkProps {
  isActive?: boolean;
}

/* AppLink is a wrapper adding custom styling to React Routers Link component */
export function MainNavLink(props: Props) {
  const { isActive, ...rest } = props;

  const linkStyle: LinkProps = {
    color: isActive ? "white" : AppColors.textDarkGray,
    textDecoration: "none",
    fontSize: "16px",
    _hover: {
      textDecoration: "none",
      color: isActive ? "white" : "#d5d5d5",
    },
  };

  return <AppLink {...linkStyle} {...rest} />;
}
