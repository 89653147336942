export const CREATIVES_SCALE_TASK_STATUS = {
  AVAILABLE: {
    id: "available",
    name: "Available",
  },
  WRITING: {
    id: "writing",
    name: "Writing",
  },
  EDITING: {
    id: "editing",
    name: "Editing",
  },
  REVIEW: {
    id: "review",
    name: "Review",
  },
  REVISION: {
    id: "revision",
    name: "Revision",
  },
  APPROVED: {
    id: "approved",
    name: "Approved",
  },
  REJECTED: {
    id: "rejected",
    name: "Rejected",
  },
  PAID: {
    id: "paid",
    name: "Paid",
  },
  COMPLETED: {
    id: "completed",
    name: "Completed",
  },
};
