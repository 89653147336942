import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  key: any;
}

export function TableRow(props: Props) {
  const { children } = props;

  return (
    <tr
      style={{
        display: "table-row",
        outline: "0",
        verticalAlign: "middle",
      }}
    >
      {children}
    </tr>
  );
}
