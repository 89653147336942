import { MainNavFull } from "./main-nav-full";
import { MainNavSmall } from "./main-nav-small";
import { useMediaQuery } from "../../util/use-media-query";

export function MainNav() {
  const shouldUseFullNav = useMediaQuery("(min-width: 1565px)");
  if (shouldUseFullNav) {
    return <MainNavFull />;
  }
  return <MainNavSmall />;
}
