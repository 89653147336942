export const ONBOARDING_STATUS = {
  WAITING_FOR_EMAIL_CONFIRM: {
    id: "waiting-for-email-confirm",
    name: "Waiting for Email Confirmation",
  },
  INTERESTS_NOT_YET_SUPPORTED: {
    id: "interests-not-yet-supported",
    name: "Interests Not Yet Supported",
  },
  COUNTRY_NOT_YET_SUPPORTED: {
    id: "country-not-yet-supported",
    name: "Country Not Yet Supported",
  },
  WAITING_FOR_INTEREST_SELECTION: {
    id: "waiting-for-interest-selection",
    name: "Waiting for Task Type Interest Selection",
  },
  READY_FOR_GRAMMAR_TEST: {
    id: "ready-for-grammar-test",
    name: "Ready to Start Grammar Test",
  },
  GRAMMAR_TEST_IN_PROGRESS: {
    id: "grammar-test-in-progress",
    name: "Grammar Test is In Progress",
  },
  WAITING_FOR_GRAMMAR_TEST_RETRY: {
    id: "waiting-for-grammar-test-retry",
    name: "Waiting for Grammar Test Retry",
  },
  READY_FOR_WRITING_SAMPLE: {
    id: "ready-for-writing-sample",
    name: "Ready for Writing Sample",
  },
  WRITING_SAMPLE_IN_PROGRESS: {
    id: "writing-sample-in-progress",
    name: "Writing Sample In Progress",
  },
  WAITING_FOR_WRITING_SAMPLE_REVIEW: {
    id: "waiting-for-writing-sample-review",
    name: "Waiting for Writing Sample Review",
  },
  WAITING_FOR_WRITING_SAMPLE_RETRY: {
    id: "waiting-for-writing-sample-retry",
    name: "Waiting for Writing Sample Retry",
  },
  READY_FOR_IDENTITY_PAYMENT_SETUP: {
    id: "ready-for-identity-payment-setup",
    name: "Ready for Identity and Payment Setup",
  },
  COMPLETED: {
    id: "completed",
    name: "Completed",
  },
};
